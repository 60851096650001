import React, { useState, useEffect } from 'react';
import './ModiFyRegistrationDetails.css'
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Popover} from 'react-bootstrap'
import ProjectsService from '../../Services/ProjectsService';
import PhoneInput from "react-phone-input-2";
import ServiceRequestModel from './ServiceRequestModel';
import OtpInputModal from '../Company/OtpInputModal';
import SRQService from '../../Services/SRQService';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast ,Slide} from 'react-toastify';

const ReplaceAnAccountableRepresentative = ({company ,confirmedRepId, usrExist}) => {

  const [isCreateSRQButtonActive, setIsCreateSRQButtonActive] = useState(false);
  const [OtpModalOpen, setOtpModalOpen] = useState(false);
  const[OtpDataFromModal,setOtpDataFromModal] = useState([]);

  const [modalShow, setModalShow] = React.useState(false);
  var otpInputModelOpenendForAction = '';

const [selectedRequestTypeCompany, setSelectedRequestTypeCompany] = useState(company);
const [existingCompany, setExistingCompany] = useState([]);

const [accountCountryCode, setAccountCountryCode] = useState("");
const [accountAdditionalDetails, setAccountAdditionalDetails] = useState("");

const [accountCheck, setAccountCheck] = useState(true);
const [isDisabled, setIsDisabled] = useState(false);
const [isDisabledCRepSelector, setIsDisabledCRepSelector] = useState(false);

const [email, setEmail] = useState("");
const [displayName, setDisplayName] = useState("");

  const [message, setMessage] = useState(" ");

  const [responseMessage, setResponseMessage] = useState(" ");

  const [selectedRequestTypeCompanyCRep, setSelectedRequestTypeCompanyCRep] = useState("");

  

  const [representativePhoneNumber, setRepresentativePhoneNumber] = useState('')
  const [representativeCountryCode, setRepresentativeCountryCode] = useState('')
  const [representativeCountry, setRepresentativeCountry] = useState({})



  function handleEmail(event) {
    let inputValue = event.target.value;
    setEmail(inputValue);
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (!emailRegex.test(inputValue)) {
      setMessage("Email Id is invalid");
    } else {
      setMessage("");
    }
  }
  const handleDisplayName = (event) => {
    let eValue = event.target.value;
    setDisplayName(eValue);
  }


const handleAccountCheckBox = (event) => {
  let isChecked = event.target.checked;
  if(isChecked)
  setDisplayName("");
  setAccountCheck(isChecked);
}



const handleSelectChange = (event) => {
  let eValue = event.target.value;
  //alert(eValue);
  setSelectedRequestTypeCompany(eValue);
  if (eValue == "Select Existing Company") {
    setIsDisabledCRepSelector(true);
    clearAllFields();
  }else{
    companyCrepDetails(eValue);
    setIsDisabledCRepSelector(false);
  }
}

const handleClearCompanySelection = () => {
  setSelectedRequestTypeCompany("");
  setSelectedRequestTypeCompanyCRep("");
  setAccountAdditionalDetails("");
  setDisplayName("");
  setEmail("");
  setRepresentativePhoneNumber("");
  setRepresentativeCountryCode("");
};

const handleClearCRepSelection = () => {
  setSelectedRequestTypeCompanyCRep("");
  setAccountAdditionalDetails("");
  setDisplayName("");
  setEmail("");
  setRepresentativePhoneNumber("");
  setRepresentativeCountryCode("");
};

const [cRepresentatives, setCRepresentatives] = useState([]);
// async function companyCrepDetails(companyId) {
//   await ProjectsService.getCompaniesCrepDetails(companyId)
//   .then((response) => {
//     setCRepresentatives(response.data.representatives);
//     console.log(response.data);
//   }).catch(error =>{
//     console.log(error);
//   });
// }

async function companyCrepDetails(companyId) {
  try {
    const response = await ProjectsService.getCompaniesCrepDetails(companyId);
    setCRepresentatives(response.data.representatives || []);
    console.log("Fetched cRepresentatives:", response.data.representatives);
  } catch (error) {
    console.error("Error fetching representatives:", error);
    setCRepresentatives([]);
  }
}



const handleSelectChangeCrep = (event) => {
  let eValue = event.target.value;
  setSelectedRequestTypeCompanyCRep(eValue);
  if (eValue == "Select Existing CRep") {
    setAccountAdditionalDetails("");
  setDisplayName("");
  setEmail("");
  setRepresentativePhoneNumber("");
  setRepresentativeCountryCode("");
    setIsDisabled(true);
  }else{
    setIsDisabled(false);
  }
}
 

const onServiceRequestCreated =  ()  =>{
  console.log("inside onServiceRequestCreated function")
  clearAllFields();
  setModalShow(false);
  window.location.href="/viewserviceRequests";
};
function clearAllFields()
{
  setSelectedRequestTypeCompanyCRep("");
  setEmail("");
  setDisplayName("");
  setRepresentativePhoneNumber("");
  setAccountAdditionalDetails("");
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      backdrop='static' 
      keyboard="False"
      dialogClassName='my-modal'
     


    >
      <Modal.Header className='modal-header'>
        <Modal.Title id="contained-modal-title-vcenter">
        Confirmation on  service request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body'>
        <p>
       {responseMessage}</p>
      </Modal.Body>
      <Modal.Footer>
      <button className="ui button comm-button-style primary" onClick={onServiceRequestCreated}>OK</button>
      </Modal.Footer>
    </Modal>
  );
}



      async function createSRQ() {
        console.log("HI FROM Creeate company")
        //e.preventDefault();
        setIsCreateSRQButtonActive(true);
        //userDetailsObject.password=password;
       
        
        otpInputModelOpenendForAction = "registerCompany";
        setOtpModalOpen(true);
        
        // 
        try {
            const response = await ProjectsService.getStrongConfirmationCode("POST",null,"registerCompany",null,null,null)
        if (response && response.data.channel == "SMS") {
            otpInputModelOpenendForAction = "registerCompany";
            
            
            setOtpModalOpen(true);
           // alert("otp entered from otp modal for deleting credentials is ---->" + OtpDataFromModal);
          }
       
            
        } catch (error) {
            console.log(error);
        }
       // setModalShow(true);  //enable here for modal testing
       
      }
      const resendOtp = async() => {
        await ProjectsService.getStrongConfirmationCode("POST",null,"registerCompany",null,null,null);
      }



      const handleOtp = (OtpDataToProject) => {
        setOtpDataFromModal(OtpDataToProject);
      }

      async function verifyOtp(OtpDataFromModal){
  
        if(OtpDataFromModal.length!==0){
        
          console.log(OtpDataFromModal)
          OtpDataFromModal  = Number(OtpDataFromModal.join(''));
          //alert("otp:"+OtpDataFromModal)
          ServiceRequestModel.companyId=selectedRequestTypeCompany;
          // ServiceRequestModel.projectId=null;
          ServiceRequestModel.requestType="ReplaceCRep";
          ServiceRequestModel.requestText=accountAdditionalDetails.replace(/(?:\r\n|\r|\n)/g, ' ');
//var existingEmail = cRepresentatives.filter((r) => r.id ==  selectedRequestTypeCompanyCRep)
                                 //   .map((cr) => {return cr.email;});
          //ServiceRequestModel.subjectExistingUser.email=existingEmail[0];
          ServiceRequestModel.subjectExistingUser.email=selectedRequestTypeCompanyCRep;
          ServiceRequestModel.subjectNewUser.displayName =displayName;
          ServiceRequestModel.subjectNewUser.email=email;
          ServiceRequestModel.subjectNewUser.mobile="+"+representativePhoneNumber;
         // ServiceRequestModel.subjectCompany.name=newCompanyName;
       
          console.log(ServiceRequestModel);
           
        
           // alert("userDetailsObject.password"+userDetailsObject.password);
     
           const response = await SRQService.createNewServiceRequest(ServiceRequestModel,OtpDataFromModal)
              .then((response) => {
                setResponseMessage(response.data.message);
          setModalShow(true);
          setOtpModalOpen(false);
          window.location.href="/viewserviceRequests";
              }).catch(error =>{


                toast.error(error.response.data.message, {

                  position: toast.POSITION.BOTTOM_RIGHT,
        
                  autoClose: 8000, //6 seconds
        
                  hideProgressBar: false,
        
                  closeOnClick: true,
        
                  pauseOnHover: true,
        
                  draggable: true,
        
                  transition: Slide
        
                });
                console.log(error);
              });
        
         }
        
          }
          useEffect(() => {
            if (company) {
              companyCrepDetails(company);
            }
            
            ProjectsService.getAllProjects()
              .then((response) => {
               let dataRequest=(response.data[0]?.companyProjects || []);
                setExistingCompany(dataRequest);
              
                //alert(dataRequest);
                var allCompany='';
                              dataRequest.forEach(companyObj => {allCompany= allCompany + companyObj.name +', '})
                //alert(allCompany);
                              if(usrExist==='false'){
                                setAccountCheck(false);
                                //setIsDisabled(true);
                                var msg='Please remove my association from the below companies and then delete my account.'
                      +'\nList of Companies: '+allCompany;
                                setAccountAdditionalDetails(msg);
                              }
              
              })
              .catch((error) => console.error("Error fetching projects:", error));
             
          }, [company]);
        
          
          useEffect(() => {
            
          
            if (confirmedRepId && cRepresentatives.length > 0) {
              const numericConfirmedRepId = Number(confirmedRepId); // Convert confirmedRepId to a number
            const defaultRep = cRepresentatives.find(rep => rep.id === numericConfirmedRepId);
          
              if (defaultRep) {
               
                if (defaultRep.email) {
                  setSelectedRequestTypeCompanyCRep(defaultRep.email);
                  console.log("Setting selectedRequestTypeCompanyCRep to:", defaultRep.email);
                }
              } else {
                console.log("No representative found matching confirmedRepId.");
              }
            }
          }, [confirmedRepId, cRepresentatives]);
        
        
        
        
        


  //  useEffect(() => {
  //   if(company!==null)

  //   {companyCrepDetails(company);}
  //       ProjectsService.getAllProjects().then((response) => {
  //         setExistingCompany(response.data[0].companyProjects);
  //       }).catch(error =>{
  //         console.log(error);
  //       });
  //     },[])
  //     useEffect(() => {
  //       if (confirmedRepId && cRepresentatives && cRepresentatives.length > 0) {
  //         const defaultRep = cRepresentatives.find(rep => rep.id === confirmedRepId);
  //         if (defaultRep) {
  //           setSelectedRequestTypeCompanyCRep(defaultRep.email);
  //         }
  //       }
  //     }, [confirmedRepId, cRepresentatives]);

      const customstyles = {
        dropdownindicator: base => ({
          ...base,
          fill: "red" // custom colour
        })
      };






      const popover = (data) =>(  
  
        <Popover id="popover-basic" style={{minWidth: '300px', textAlign: 'center', borderColor:'black',borderStyle:'solid'}}>    
          <Popover.Body className='row'>  
            <p  className='row'> {data}</p>
          </Popover.Body>  
        </Popover>  
      ); 

      const renderEmailbox = () =>{
  return(
    <>
    <li>
        <div className="custom-input-wrapp mb-4">
          <input
            type="text"
            className="inputText"
            tabIndex={1}
            required=""
            id="inputEmailCrep"
            placeholder="Email Id"
            disabled={isDisabled}
            value = {email} 
            onChange = {e => handleEmail(e)}
          />
          <span className="input-info-label ms-0">Eg: username@domain.com</span>
          <span className="required-field-op">(Required)</span>
          <div style = {{ color: "red" }}> {message} </div>
        </div>
      </li>
    </>
  )
}

    return (
      <>
        <li>
          <div className="sec">
            <div className="head-titlw-w head-titl-unset">
              <h3
                style={{ fontSize: "15px" }}
                className="sec-title"
                id="ManageCompany-ModifyCompany-promptForModifyCompany"
                data-info=""
              >
                REPLACE AN ACCOUNTABLE REPRESENTATIVE
              </h3>
            </div>
            <ul className="serv-req sec-inner-wrap">
              <li style={{
          opacity: usrExist=='false' ? 0.60 : 1,
          pointerEvents: usrExist=='false' ? "none" : "initial"
        }}>
                <div
                  className="dropdown-w-info mt-3"
                  id="selectModifyCompanyWrapper"
                  style={{ display: "flex" ,position:'relative'}}
                >
                  {selectedRequestTypeCompany ? (
                      
                      <span 
                      id='clear-icon-ReplaceAccRep-company'
                        className="clear-icon-ReplaceAccRep-company no-ligatures"
                        onClick={handleClearCompanySelection}
                      >
                        &times;
                      </span>
                    ) : (
                      <i className="dropdown icon" />
                    )}
                  <div className="width-full">
                    <Form.Select
                      defaultValue="Select Existing Company"
                      value={selectedRequestTypeCompany}
                      onChange={handleSelectChange}
                      styles={customstyles}
                      style={{ width: "100%" }}
                      className={selectedRequestTypeCompany ? 'dropdown-with-close' : 'dropdown-default'}
                    >
                      <option className="textdefault">
                        {"Select Existing Company"}
                      </option>

                      {existingCompany &&
                        existingCompany.map((cp) => {
                          //alert(cp.name);
                          return <option value={cp.id} >{cp.name}</option>;
                        })}
                    </Form.Select>
                  </div>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={popover(
                      "Please select the relevant company."
                    )}
                  >
                    <a
                      className="info-icon-w ms-2"
                      id="infoPopOverModifyCompany"
                      target="_blank"
                    >
                      <span className="bi bi-info-circle mt-2" />
                    </a>
                  </OverlayTrigger>
                </div>
              </li>


              <li style={{
          opacity: usrExist=='false' ? 0.60 : 1,
          pointerEvents: usrExist=='false' ? "none" : "initial"
        }}>
                <div
                  className="dropdown-w-info mt-3"
                  style={{ display: "flex",position:'relative' }}
                >
                   {selectedRequestTypeCompanyCRep ? (
                      
                      <span 
                      id='clear-icon-Crep-ReplaceAccRep'
                        className="clear-icon-Crep-ReplaceAccRep no-ligatures"
                        onClick={handleClearCRepSelection}
                      >
                        &times;
                      </span>
                    ) : (
                      <i className="dropdown icon" />
                    )}
                  <div className="width-full">
                    <Form.Select
                      //defaultValue="Select Existing CRep"
                      value={selectedRequestTypeCompanyCRep || "Select Existing CRep"}
                      onChange={handleSelectChangeCrep}
                      styles={customstyles}
                      style={{ width: "100%" }}
                      disabled={isDisabledCRepSelector || selectedRequestTypeCompany===""}
                      className={selectedRequestTypeCompanyCRep ? 'dropdown-with-close' : 'dropdown-default'}
                    >
                      <option className="textdefault">
                        {"Select Existing CRep"}
                      </option>

                      {cRepresentatives &&
                        cRepresentatives
                        .filter((r) => r.isConfirmed )
                        .map((cr) => {
                          return <option value={cr.email}>{cr.name}</option>;
                        })}
                    </Form.Select>
                  </div>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={popover(
                      "Select the representative to be removed. Only accountable representatives are available in the list. If you wish to replace a delegate you can use the self-service portal to do so by editing the company details and replacing the delegate directly from the list of delegates."
                    )}
                  >
                    <a
                      className="info-icon-w ms-2"
                      id="infoPopOverModifyCompany"
                      target="_blank"
                    >
                      <span className="bi bi-info-circle mt-2" />
                    </a>
                  </OverlayTrigger>
                </div>
              </li>

              <>
                <div className="awesome">
                  <ul className="serv-req sec-inner-wrap">
                    <li className="user-exist-w" style={{
          opacity: usrExist=='false' ? 0.60 : 1,
          pointerEvents: usrExist=='false' ? "none" : "initial"
        }}>
                      <div className="form-check mb-3">
                        <label className="mt-2 form-check-label">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="remember"
                            onChange={(e) => handleAccountCheckBox(e)}
                            defaultChecked={usrExist=='false' ? false : accountCheck}
                            disabled={isDisabled}
                          />{" "}
                          User already exist
                        </label>
                      </div>
                    </li>

                    {accountCheck && accountCheck ? (
                      <>{renderEmailbox()}</>
                    ) : (
                      <>
                        <li id="inputDisplayNameCrepWrapper" style={{}}>
                          <div className="custom-input-wrapp mb-4">
                            <input
                              type="text"
                              className="inputText"
                              required=""
                              maxLength={240}
                              tabIndex={1}
                              id="inputDisplayNameCrep"
                              placeholder="Display Name"
                              disabled={isDisabled}
                              value = {displayName} 
                              onChange = {e => handleDisplayName(e)}
                            />
                            <span className="input-info-label">
                              Eg: Username
                            </span>
                            <span className="required-field-op">
                              (Required)
                            </span>
                            <span
                              className="form-error error-message-show"
                              style={{ visibility: "hidden" }}
                            >
                              Display Name is invalid
                            </span>
                          </div>
                        </li>
                        {renderEmailbox()}
                        <li
                          className="select-drop-message"
                          id="inputMobileNoCrepWrapper"
                          style={{}}
                        >
                          <div className="contact-num-wrap">
                            <div className="country-code-w">
                            <PhoneInput
        country={"in"}
        value={representativePhoneNumber}
        enableSearch={true}
        onChange={(phone,country) => {setRepresentativePhoneNumber(phone)
                                 setRepresentativeCountryCode(country.countryCode)
                                 setRepresentativeCountry(country)
        }}
        placeholder="Enter phone number"
        inputProps={{
            required: true
        
          }}
          disabled={isDisabled}
        
      />
      

                            </div>
                            <span className="required-field-op">(Required)</span>
                            
                          </div>
                        </li>
                      </>
                    )}

                    <li>
                      <div className="custom-input-wrapp mt-3 mb-3">
                        <div className="dropdown-w-info">
                          <div className="width-full">
                            <textarea
                              className="cust-text-a"
                              maxLength={4000}
                              placeholder="Additional Details"
                              id="textAreaModifyAdditionalDetails"
                              tabIndex={1}
                              style={{fontSize: '14px'}}
                              value={accountAdditionalDetails}
                              onChange={(e) =>
                                setAccountAdditionalDetails(e.target.value)
                              }
                              disabled={!selectedRequestTypeCompanyCRep || selectedRequestTypeCompanyCRep===""}
                            />
                          </div>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={popover(
                              "Please provide any additional information relevant to the change and to the registration process of the new user here."
                            )}
                          >
                            <a
                              className="info-icon-w pop-common-sel sr-pophover visible"
                              id="infoPopOverModifyAdditionalDetails"
                              style={{ marginLeft: 5, visibility: "visible" }}
                              target="_blank"
                            >
                              <span className="bi bi-info-circle mt-2" />
                            </a>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            </ul>
          </div>
        </li>
        <li className="apim-pop-button-w comm-button-wrap ser-req-btn">
{(!isCreateSRQButtonActive&&((!accountCheck&&email&&displayName&&representativePhoneNumber)||(accountCheck&&email)))?
  <button
            className="comm-button-style primary btn-disable"
            tabIndex={1}
            onClick={() => createSRQ()}
            >
            Create
          </button>:
          <button className="button comm-button-style primary btn-disable" id="btnSubmitRegisterCompany" tabindex="1" 
          disabled>{!isCreateSRQButtonActive?<>Create</>:<>Creating Service Request..</>}</button>
}
<a
            className="button comm-button-style secondary"
            href="/serviceRequest"
          >
            Cancel
          </a>
        </li>
        <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
       <div className="toast-container"><ToastContainer limit={2}/></div>
        {OtpModalOpen &&<OtpInputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={otpInputModelOpenendForAction} resendOtp={resendOtp}/>} 
   
      </>
    );
    }
    export default ReplaceAnAccountableRepresentative;